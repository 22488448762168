import React,{useContext, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';

import { ThemeContext } from "../context/ThemeContext";
import BanerLogoSlider from './../components/Home/BanerLogoSlider';
import CategoriesSlider from './../components/Home/CategoriesSlider';
import TeamSlider from './../components/Home/TeamSlider';
import PartnershipSlider from './../components/Home/PartnershipSlider';
import RecentProjectsSlider from './../components/Home/RecentProjectsSlider';
import TestimonialSlider from './../components/Home/TestimonialSlider';
import StoryBlog from './../components/Home/StoryBlog';
import CounterBlog from './../components/Home/CounterBlog';
import NewsSlider from './../components/Home/NewsSlider';
import UpdateBlog from './../components/Home/UpdateBlog';
import DonateModal from './../components/Modal/DonateModal';



//images
import main from './../assets/images/main-slider/pic1.png';
import { IMAGES } from '../constant/theme';
import AffiliateList from "./affiliate-views/components/affiliate-list";
import AffiliateListSlider from "./affiliate-views/components/affiliate-list-slider";
import pic1 from "../assets/images/project/pic1.jpg";
import avat1 from "../assets/images/avatar/avatar1.jpg";
import pic2 from "../assets/images/project/pic2.jpg";
import avat2 from "../assets/images/avatar/avatar2.jpg";
import pic3 from "../assets/images/project/pic3.jpg";
import avat3 from "../assets/images/avatar/avatar3.jpg";
import pic4 from "../assets/images/project/pic4.jpg";
import avat4 from "../assets/images/avatar/avatar4.jpg";
import pic5 from "../assets/images/project/pic5.jpg";
import avat5 from "../assets/images/avatar/avatar5.jpg";
import pic6 from "../assets/images/project/pic6.jpg";
import avat6 from "../assets/images/avatar/avatar6.jpg";
import pic7 from "../assets/images/project/pic7.jpg";
import avat7 from "../assets/images/avatar/avatar7.jpg";
import pic8 from "../assets/images/project/pic8.jpg";
import avat8 from "../assets/images/avatar/avatar8.jpg";
import pic9 from "../assets/images/project/pic9.jpg";
import avat9 from "../assets/images/avatar/avatar9.jpg";
import pic10 from "../assets/images/project/pic10.jpg";
import pic11 from "../assets/images/project/pic11.jpg";
import pic12 from "../assets/images/project/pic12.jpg";
import ProgramListSlider from "./program/components/program-list-slider";

const Home = () => {
    const modalRef = useRef();
    const { changeBackground, changePrimaryColor } = useContext(ThemeContext);
    useEffect(() => {
        changeBackground({ value: "default", label: "default" });
        changePrimaryColor("color-skin-1");
    }, []);
    
    return(
        <>
            <div className="page-content bg-white">	
                <div className="banner-one">
                    <div className="container">
                        <div className="row banner-inner">
                            <div className="col-lg-8 col-md-7 h-100 d-flex align-items-center">
                                <div className="banner-content" >
                                    <h5 className="sub-title text-primary">Donasi</h5>
                                    <h1 className="title">
                                        Layanan kami memberikan kemudahan bagi Anda dalam berdonasi
                                    </h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
                                    <Link to={"/about-us"} className="btn btn-secondary btnhover2 m-r15">Learn More <i className="flaticon-right-arrows ms-3 scale1"></i></Link>
                                    <Link to={"#"} className="btn btn-light"  onClick={() => {modalRef.current.handleModalOpen(); }}
                                        data-bs-target="#modalDonate">Donasi
                                    </Link>
                                    {/*<BanerLogoSlider />*/}
                                    
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <div className="banner-media">
                                    <img src={main} className="main-img" alt="" />
                                    <div className="dz-media">
                                        <img src={IMAGES.Shape} className="main-img" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dz-shape">
                            <img src={IMAGES.Shape1} className="shape-1 move-2" alt="shape" />
                            <img src={IMAGES.Shape2} className="shape-2 move-2" alt="shape" />
                            <img src={IMAGES.Shape1} className="shape-3 move-2" alt="shape" />
                            <img src={IMAGES.Shape2} className="shape-4 move-2" alt="shape" />
                        </div>
                    </div>
                </div>
            
                <div className="content-inner-3 section-pattren1">
                    <div className="container">
                        <div className="section-head text-center wow fadeInUp" data-wow-delay="0.2s">
                            <h5 className="sub-title">KATEGORI</h5>
                            <h2 className="title">Bahtera Dhuafa memiliki program-program utama <br/> dengan tujuan besar mengentaskan kemiskinan</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                        <CategoriesSlider />    
                    </div>
                    <img src={IMAGES.Shape1} className="shape-1" alt="shape" />
                    <img src={IMAGES.Shape2} className="shape-2" alt="shape" />
                    <img src={IMAGES.Shape3} className="shape-3" alt="shape" />
                    <img src={IMAGES.Shape5} className="shape-4" alt="shape" />
                    <img src={IMAGES.Shape6} className="shape-5" alt="shape" />
                </div>

                <section className="content-inner-3">
                    <div className="container">
                        <StoryBlog />
                    </div>
                </section>



                {/*<section className="clients-wrapper wow fadeInUp" data-wow-delay="0.2s">*/}
                {/*    <div className="container">*/}
                {/*        <div className="section-head text-center">*/}
                {/*            <h4 className="title">Our Partnership</h4>*/}
                {/*        </div>*/}
                {/*        <PartnershipSlider />    */}
                {/*    </div>*/}
                {/*</section>*/}



                <section className="content-inner-3 overflow-hidden section-pattren1">
                    <div className="container">
                        <div className="section-head text-center wow fadeInUp" data-wow-delay="0.2s">
                            <h2>Program Kami</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                        <ProgramListSlider data={[
                            {cardid:'3', image: pic1, image2: avat1, progres:"75%", title:"EDUCATION", subtitle:"New vaccine for cattle calf loss learned", authar:"Cheyenne Curtis", raised:"3,542", days:"43"},
                            {cardid:'4', image: pic2, image2: avat2, progres:"85%", title:"TECHNOLOGY", subtitle:"He Created the Web. Now He’s Out to Remake", authar:"Kaylynn Donin", raised:"35,542", days:"63"},
                            {cardid:'1', image: pic3, image2: avat3, progres:"70%", title:"FOOD", subtitle:"4 Things parents learned for they jids in 2020", authar:"Adam Jordon", raised:" 2,542", days:"23"},
                            {cardid:'4', image: pic4, image2: avat4, progres:"40%", title:"FRIENDS", subtitle:"Partnering to create a community", authar:"Kaylynn Donin", raised:"6,542", days:"35"},
                            {cardid:'3', image: pic5, image2: avat5, progres:"30%", title:"HEALTH", subtitle:"Primary School Build for Children", authar:"Richard Hart", raised:"1,542", days:"47"},
                            {cardid:'1', image: pic6, image2: avat6, progres:"50%", title:"CHILDRENS", subtitle:"Best & Less published their supplier lists ", authar:"Cheyenne Curtis", raised:"8,354", days:"75"},
                            {cardid:'4', image: pic7, image2: avat7, progres:"75%", title:"EDUCATION", subtitle:"New vaccine for cattle calf loss learned", authar:"Cheyenne Curtis", raised:"3,542", days:"43"},
                            {cardid:'1', image: pic8, image2: avat8, progres:"85%", title:"TECHNOLOGY", subtitle:"He Created the Web. Now He’s Out to Remake", authar:"Kaylynn Donin", raised:"35,542", days:"63"},
                            {cardid:'3', image: pic9, image2: avat9, progres:"70%", title:"FOOD", subtitle:"4 Things parents learned for they jids in 2020", authar:"Adam Jordon", raised:" 2,542", days:"23"},
                            {cardid:'1', image: pic10, image2: avat4, progres:"40%", title:"FRIENDS", subtitle:"Partnering to create a community", authar:"Kaylynn Donin", raised:"6,542", days:"35"},
                            {cardid:'2', image: pic11, image2: avat3, progres:"30%", title:"HEALTH", subtitle:"Primary School Build for Children", authar:"Richard Hart", raised:"1,542", days:"47"},
                            {cardid:'1', image: pic12, image2: avat1, progres:"50%", title:"CHILDRENS", subtitle:"Best & Less published their supplier lists ", authar:"Cheyenne Curtis", raised:"8,354", days:"75"},
                        ]}/>
                    </div>
                </section>


                <section className="content-inner-3 overflow-hidden section-pattren1">
                    <div className="container">
                        <div className="section-head text-center wow fadeInUp" data-wow-delay="0.2s">
                            <h2>Afiliasi</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                        <AffiliateListSlider data={[
                            {cardid:'1', image: pic8, image2: avat8, progres:"85%", title:"TECHNOLOGY", subtitle:"He Created the Web. Now He’s Out to Remake", authar:"Kaylynn Donin", raised:"35,542", days:"63"},
                            {cardid:'3', image: pic9, image2: avat9, progres:"70%", title:"FOOD", subtitle:"4 Things parents learned for they jids in 2020", authar:"Adam Jordon", raised:" 2,542", days:"23"},
                            {cardid:'1', image: pic10, image2: avat4, progres:"40%", title:"FRIENDS", subtitle:"Partnering to create a community", authar:"Kaylynn Donin", raised:"6,542", days:"35"},
                            {cardid:'3', image: pic5, image2: avat5, progres:"30%", title:"HEALTH", subtitle:"Primary School Build for Children", authar:"Richard Hart", raised:"1,542", days:"47"},
                            {cardid:'1', image: pic6, image2: avat6, progres:"50%", title:"CHILDRENS", subtitle:"Best & Less published their supplier lists ", authar:"Cheyenne Curtis", raised:"8,354", days:"75"},
                            {cardid:'4', image: pic7, image2: avat7, progres:"75%", title:"EDUCATION", subtitle:"New vaccine for cattle calf loss learned", authar:"Cheyenne Curtis", raised:"3,542", days:"43"},
                            {cardid:'3', image: pic1, image2: avat1, progres:"75%", title:"EDUCATION", subtitle:"New vaccine for cattle calf loss learned", authar:"Cheyenne Curtis", raised:"3,542", days:"43"},
                            {cardid:'4', image: pic2, image2: avat2, progres:"85%", title:"TECHNOLOGY", subtitle:"He Created the Web. Now He’s Out to Remake", authar:"Kaylynn Donin", raised:"35,542", days:"63"},
                            {cardid:'1', image: pic3, image2: avat3, progres:"70%", title:"FOOD", subtitle:"4 Things parents learned for they jids in 2020", authar:"Adam Jordon", raised:" 2,542", days:"23"},
                            {cardid:'4', image: pic4, image2: avat4, progres:"40%", title:"FRIENDS", subtitle:"Partnering to create a community", authar:"Kaylynn Donin", raised:"6,542", days:"35"},
                            {cardid:'2', image: pic11, image2: avat3, progres:"30%", title:"HEALTH", subtitle:"Primary School Build for Children", authar:"Richard Hart", raised:"1,542", days:"47"},
                            {cardid:'1', image: pic12, image2: avat1, progres:"50%", title:"CHILDRENS", subtitle:"Best & Less published their supplier lists ", authar:"Cheyenne Curtis", raised:"8,354", days:"75"},
                        ]}/>
                    </div>
                </section>


                {/*<section className="testimonial-wrapper1 content-inner">*/}
                {/*    <div className="section-head text-center wow fadeInUp" data-wow-delay="0.2s">*/}
                {/*        <h2 className="title">Testimonials</h2>*/}
                {/*        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>*/}
                {/*    </div>*/}
                {/*    <TestimonialSlider />*/}
                {/*</section>*/}



                <div className="counter-wrapper-1 content-inner-3">
                    <div className="container">
                        <div className="counter-inner bg-secondary">
                            <div className="row">
                                <CounterBlog />
                            </div>
                            <img src={IMAGES.Pattren1} className="pattren1 move-2" alt="" />
                            <img src={IMAGES.Pattren2} className="pattren2 move-2" alt="" />
                            <img src={IMAGES.Pattren3} className="pattren3 move-2" alt="" />
                            <img src={IMAGES.Pattren4} className="pattren4 move-2" alt="" />
                            <img src={IMAGES.Pattren5} className="pattren5 move-2" alt="" />
                            <img src={IMAGES.Pattren6} className="pattren6 move-2" alt="" />
                        </div>
                    </div>
                </div>
                <section className="content-inner-2 section-pattren1">
                    <div className="container">
                        <div className="section-head text-center wow fadeInUp" data-wow-delay="0.2s">
                            <h2 className="title">Berita Terbaru</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                         <NewsSlider />   
                    </div>
                    <img src={IMAGES.Shape2} className="shape-6 move-2" alt="shape" />
                    <img src={IMAGES.Shape3} className="shape-5 move-1" alt="shape" />
                    <img src={IMAGES.Shape5} className="shape-1 rotating" alt="shape" />
                    <img src={IMAGES.Shape1} className="shape-3 move-1" alt="shape" />
                    <img src={IMAGES.Shape6} className="shape-4 rotating" alt="shape" />
                    <img src={IMAGES.Shape6} className="shape-2 rotating" alt="shape" />
                </section>
                <div className="call-action style-1 content-inner-1">
                    <div className="container">
                        <UpdateBlog />
                    </div>
                </div>
            </div>

            {/* Modals */}
            {/* <Modal className="modal fade modal-wrapper" id="modalDonate" show={donateModal} onHide={setDonateModal} centered> 
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Choose a donation amount</h5>
                        <button type="button" className="btn-close" onClick={()=>setDonateModal(false)}><i className="flaticon-close"></i></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(e)=>FormSubmit(e)}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="tag-donate style-1">
                                        <div className="donate-categories">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    $500
                                                </label>
                                            </div>
                                        </div>
                                        <div className="donate-categories">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked  />
                                                <label className="form-check-label" htmlFo="flexRadioDefault2">
                                                    $1000
                                                </label>
                                            </div>
                                        </div>
                                        <div className="donate-categories">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" defaultChecked />
                                                <label className="form-check-label" htmlFo="flexRadioDefault3">
                                                    $2000
                                                </label>
                                            </div>
                                        </div>
                                        <div className="donate-categories">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" defaultChecked />
                                                <label className="form-check-label" htmlFo="flexRadioDefault4">
                                                    $5000
                                                </label>
                                            </div>
                                        </div>
                                        <p>Most Donors donate approx <span>$1000</span> to this Fundraiser</p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label className="form-label">Other Amount</label>
                                        <div className="input-group">
                                            <input type="number" className="form-control"  placeholder="Other Amount" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label className="form-label">Name</label>
                                        <div className="input-group">
                                            <input name="dzName" required="" type="text" className="form-control" placeholder="Jakob Bothman" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label className="form-label">Email address</label>
                                        <div className="input-group">
                                            <input name="dzEmail" required type="text" className="form-control" placeholder="info@mail.com" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label className="form-label">Phone Number</label>
                                        <div className="input-group">
                                            <input type="number" className="form-control" placeholder="Phone Number" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label className="form-label">Address</label>
                                        <div className="input-group">
                                            <input required type="text" className="form-control" placeholder="Address" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label className="form-label">Pancard</label>
                                        <div className="input-group">
                                            <input type="number" className="form-control" placeholder="Pancard" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mb-0 text-center">
                                        <button name="submit" type="submit" value="Submit" className="btn btn-primary btn-block">Proceed To Pay</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>                
            </Modal> */}
            <DonateModal 
                ref={modalRef}
            />
        </>
    )
}
export default Home;