import {AnimatePresence, motion} from "framer-motion";
import {Link} from "react-router-dom";
import React from "react";
import PropTypes from 'prop-types'
function ListCardProgram({data}){

    return (
        <div className="clearfix">
            <ul
                //layout
                id="masonry" className="row"
                //transition={{ duration: 0.3 }}
            >
                <AnimatePresence>
                    {
                        Array.isArray(data) &&
                        data.length > 0 &&
                        data?.map((item, index)=>{
                        return(
                            <motion.li
                                layout
                                initial={{ scale: 0, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                className="card-container col-xl-4 col-lg-6 col-md-6 col-sm-12 Fashion m-b30" key={index}
                                //transition={{ duration: 0.5 }}
                            >

                                <div className="dz-card style-2 overlay-skew">
                                    <div className="dz-media">
                                        <Link to={`/program/${item?.id ?? item?.cardid}`}><img src={item.image} alt="" /></Link>
                                    </div>
                                    <div className="dz-info">
                                        <ul className="dz-category">
                                            <li><Link to={"#"}>{item.title}</Link></li>
                                        </ul>
                                        <h5 className="dz-title">  <Link to={`/program/${item?.id ?? item?.cardid}`}>{item.subtitle} </Link></h5>
                                        {/*<div className="progress-bx style-1">*/}
                                        {/*    <div className="progress">*/}
                                        {/*        <div className="progress-bar progress-bar-secondary progress-bar-striped progress-bar-animated" style={{width:item?.progress}}></div>*/}
                                        {/*    </div>*/}
                                        {/*    <ul className="progress-tag">*/}
                                        {/*        <li className="raised">*/}
                                        {/*            <i className="las la-coins"></i> Raised: <span className="text-primary">$ {item.raised}</span>*/}
                                        {/*        </li>*/}
                                        {/*        <li className="goal">*/}
                                        {/*            <i className="lar la-calendar"></i> Goal: <span className="text-primary">$70,000</span>*/}
                                        {/*        </li>*/}
                                        {/*    </ul>*/}
                                        {/*</div>*/}
                                        {/*<div className="author-wrappper">*/}
                                        {/*    <div className="author-media">*/}
                                        {/*        <img src={item.image2} alt="" />*/}
                                        {/*    </div>*/}
                                        {/*    <div className="author-content">*/}
                                        {/*        <div className="author-head">*/}
                                        {/*            <h6 className="author-name">{item?.author}</h6>*/}
                                        {/*            <ul className="rating-list">*/}
                                        {/*                <li><i className="fa fa-star"></i></li>*/}
                                        {/*                {" "}<li><i className="fa fa-star"></i></li>*/}
                                        {/*                {" "}<li><i className="fa fa-star"></i></li>*/}
                                        {/*                {" "}<li><i className="fa fa-star gray-light"></i></li>*/}
                                        {/*                {" "}<li><i className="fa fa-star gray-light"></i></li>*/}
                                        {/*            </ul>*/}
                                        {/*        </div>*/}
                                        {/*        <ul className="author-meta">*/}
                                        {/*            <li className="campaign">12 Campaign</li>*/}
                                        {/*            <li className="location">New York, London</li>*/}
                                        {/*        </ul>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </motion.li>

                        )
                    })}
                </AnimatePresence>
            </ul>
        </div>
    )
}

ListCardProgram.propTypes ={
    data: PropTypes.array.isRequired
}
ListCardProgram.defaultProps = {
    data: []
}
export default ListCardProgram