import React from 'react';
import {Link} from 'react-router-dom';

const FooterCommonData = ({logoImage, iconStyle}) => {
    return (
        <>
            <div className="col-xl-4 col-lg-12 col-md-12">
                <div className="widget widget_about">
                    <div className="footer-logo logo-white">
                        <Link to={"/"}><img src={logoImage} alt="" /></Link> 
                    </div>
                    <p>Lorem ipsum dolor sit amet lorem ipsu</p>
                    <div className="dz-social-icon style-1">
                        <ul>
                            <li><a className="fab fa-facebook-f"  rel="noreferrer" href="#"></a></li>
                            {" "}<li><a className="fab fa-instagram"  rel="noreferrer" href="#"></a></li>
                            {" "}<li><a className="fab fa-twitter"  rel="noreferrer" href="#"></a></li>
                            {" "}<li><a className="fab fa-youtube"  rel="noreferrer" href="#"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-3 col-6">
                <div className="widget widget_services">
                    <h5 className="footer-title">Resources</h5>
                    <ul>
                        <li><Link to={"#"}>FAQ</Link></li>
                        <li><Link to={"#"}>Mission</Link></li>
                       <li><Link to={"#"}>Terms And Condition</Link></li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-3 col-6">
                <div className="widget widget_services">
                    <h5 className="footer-title">Company</h5>
                    <ul>
                        <li><Link to={"/"}>Home</Link></li>
                        <li><Link to={"/about-us"}>Tentang Kami</Link></li>
                        <li><Link to={"/program"}>Program Kami</Link></li>
                        <li><Link to={"/affiliate"}>Program Afiliasi</Link></li>
                        <li><Link to={"/categories"}>Kategori</Link></li>
                        <li><Link to={"/blog"}>Berita</Link></li>
                        <li><Link to={"/contact-us"}>Kontak</Link></li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="widget widget_getintuch">
                    <h5 className="footer-title">Get in Touch with Us</h5>
                    <ul>
                        <li>
                            {iconStyle   ? 
                                <i className="fas fa-map-marker-alt text-primary"></i>
                                :  
                                <i className="fas fa-map-marker-alt"></i>
                            }
                            <span>{process.env.REACT_APP_ADDRESS}</span>
                        </li>
                        <li>
                            {iconStyle   ? 
                                <i className="fa-solid fa-phone text-primary"></i>
                                :
                                <i className="fa-solid fa-phone"></i>
                            }
                            <span>{process.env.REACT_APP_APP_CONTACT}</span>
                        </li>
                        <li>
                            {iconStyle   ? 
                                <i className="fa fa-envelope text-primary"></i> 
                                :
                                <i className="fa fa-envelope"></i> 
                            }
                            <span>{process.env.REACT_APP_APP_MAIL}</span>
                        </li>
                    </ul>
                </div>
            </div> 
        </>
    );
};

export default FooterCommonData;
