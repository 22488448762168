import React from 'react'
import AffiliateCategoryList from "../../components/shared-layouts/affiliates/affiliate-category-list";
import {useParams} from "react-router-dom";

export default function DetailCategoryPages(){
    const {id} = useParams()
    return (
        <div className="w-full">
            <div className={'container'}>
                <div className={'w-100 py-4'} style={{border:'1px solid red'}}>
                    <h1>{id}</h1>
                </div>

                <AffiliateCategoryList title={'Affiliate'}/>
            </div>

        </div>
    )
}