import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import axios from "axios";

export default function CategoriesViews() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        axios.get(
            [
                window.origin, 'api/v1/category.json'
            ].join('/')
        ).then((response) => {
            setData([...response?.data])
            setLoading(false)
        })
            .catch((err) => {
                setData([])
                setLoading(false)
            })
    })
    return (
        <div className="w-100 py-4 my-4">
            <div className="container">
                <div className="mb-4">
                    <h1>Category List</h1>
                </div>
                <div className="row g-4">
                    {
                        data.map((item, index) => {
                            return (
                                <div className="col-lg-3">
                                    <div className="icon-bx-wraper text-center style-1 wow fadeInUp"
                                         data-wow-delay="0.2s">
                                        <div className="icon-lg m-sm-b20 m-b30">
                                            <Link to={`#`} className="icon-cell">
                                                <i className={item?.icon}></i>
                                            </Link>
                                            {/*<Link to={`/categories/${item?.title?.toString()?.toLowerCase()?.replace(/ /g,'-')}`} className="icon-cell">*/}
                                            {/*    <i className={item?.icon}></i>*/}
                                            {/*</Link>*/}

                                        </div>
                                        <div className="icon-content">
                                            <h6 className="dz-tilte m-b5 text-capitalize">
                                                <Link to={`#`}>{item?.title}</Link>
                                            </h6>
                                                {/*<Link to={`/categories/${item?.title?.toString()?.toLowerCase()}`}>{item?.title}</Link></h6>*/}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}