import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
function AffiliateCategoryList(props){
    let { category, title } = props
    const [ data , setData] = useState([])
    const [ loading , setLoading ] = useState(false)

    useEffect(()=> {
        if(category){
            setLoading(true)

        }
    },[category])
    return (
        <div>
            {
                title && (
                    <div>
                        <h2>{title}</h2>
                    </div>
                )
            }
        </div>
    )
}

AffiliateCategoryList.propTypes = {
    title: PropTypes.string,
    category: PropTypes.string.isRequired
}
AffiliateCategoryList.defaultProps = {
    title: null,
    category: null
}

export default AffiliateCategoryList