import React from 'react'
import {AnimatePresence, motion} from "framer-motion";
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";

export default function ProgramListSlider({data}){
    return (
        <Swiper className="recent-blog p-b5"
                speed= {1500}
            //parallax= {true}
                slidesPerView= {3}
                spaceBetween= {30}
                loop={true}
                autoplay= {{
                    delay: 3000,
                }}
                modules={[ Autoplay ]}
                breakpoints = {{
                    1200: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    320: {
                        slidesPerView: 1,
                    },
                }}
        >

            {data.map((d,i)=>(
                <SwiperSlide key={i}>
                    <div className="dz-card style-2 overlay-skew wow fadeInUp" data-wow-delay="0.2s">
                        <div className="dz-media">
                            <Link to={`/program/${d?.cardid ?? d?.id}`}><img src={d.image} alt="" /></Link>
                        </div>
                        <div className="dz-info">
                            <ul className="dz-category">
                                <li><Link to={"#"}>EDUCATION</Link></li>
                            </ul>
                            <h5 className="dz-title">
                                <Link to={`/program/${d?.cardid ?? d?.id}`}>
                                    New vaccine for cattle calf loss learned</Link></h5>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}